@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@700&family=Nunito+Sans:wght@900&display=swap');
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");

.wrapper {
  background: url(./bg.jpg);
  background-size: cover;
}

.game-spectator {
  pointer-events: none;
}

.documents {
  pointer-events: all;
}

.screen {
  padding: 60px 90px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.metric {
  font-size: 37.24px;
  font-family:'Nunito Sans', sans-serif;
  fill:#fde2bc;
  text-transform: uppercase;
}

.video {
  mask: url(./mask.svg);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.login-form {
  font-size: 20px;
  display: flex;
  position: relative;
  z-index: 1;
}

.invalid-code {
  background: #db4e32 !important;
  color: #fde2bc !important;
}

.login-form .field-text {
  width: 200px;
  margin-right: 10px;
}

.login-form .field-checkbox {
  width: 200px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form .field-checkbox span {
  margin-left: 10px;
}

.btn-navs {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.btn-navs-item {
  width: 30px;
  margin: 0 10px;
  color: #fde2bc;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 1.3;
  cursor: pointer;
}

.btn-navs-item.current {
  opacity: 1;
}

.btn-navs-item .btn-navs-num {
  opacity: 0.5;
}

.btn-navs-item.current .btn-navs-num {
  opacity: 1;
}

.btn-nav-left {
  font-size: 90px;
  position: absolute;
  top: 50%;
  margin-top: -80px;
  left: 17px;
  outline: none;
  color: #fde2bc;
  cursor: pointer;
}

.btn-nav-right {
  font-size: 90px;
  position: absolute;
  top: 50%;
  margin-top: -80px;
  right: 17px;
  outline: none;
  color: #fde2bc;
  cursor: pointer;
}

.quit {
  font-family: 'Courier Prime', monospace;
  font-size:14px;
  text-align: center;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.spectator-url {
  font-family: 'Courier Prime', monospace;
  font-size:12px;
  text-align: center;
  position: absolute;
  top: 5px;
  left: 5px;
  cursor: pointer;
}

.login-master {
  font-family: 'Courier Prime', monospace;
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 5px;
  left: 5px;
  cursor: pointer;
  text-decoration: underline;
}

.team-code {
  font-family: 'Courier Prime', monospace;
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 5px;
  left: 5px;
}

.question {
  color:#fde2bc;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.question-disabled .question-title,
.question-disabled .question-form {
  pointer-events: none;
}

.question-title {
  font-size: 30px;
  font-family:'Nunito Sans', sans-serif;
  text-transform: uppercase;
  color:#eeab36;
  margin-bottom: 20px;
  text-align: center;
}

.question-form {
  margin: 0 15px;
}

.question-form div {
  font-size: 25px !important;
  font-family: 'Courier Prime', monospace !important;
}

.question-status {
  font-family: 'Courier Prime', monospace;
  font-size:20px;
  text-align: center;
  padding: 7px 14px 5px 14px;
  position: absolute;
  bottom: 30px;
  left: 40px;
}

.field-text {
  padding: 0.5em 0.5em 0.4em 0.5em;
  outline: none;
  width: 100%;
  background: #eeab36;
  color: #31241a;
  font-family: 'Courier Prime', monospace;
}

.field-checkbox {
  padding: 0.5em 0.5em 0.4em 0.5em;
  outline: none;
  width: 100%;
  color: #31241a;
  font-family: 'Courier Prime', monospace;
}

.is-invalid {
  text-decoration: underline dotted #db4e32;
}

.question-form .chakra-radio-group {
  line-height: 1em;
}

.question-form .chakra-radio-group * {
  font-size: 21px !important;
  font-family: 'Courier Prime', monospace;
  letter-spacing: 0.04em;
}

.question-form .chakra-radio__control {
  margin-right: 0.5em;
}

.submit-wrapper {
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.choice-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.question-complete {
  pointer-events: none;
}

.btn {
  background: #eeab36;
  color: #31241a;
  font-size:20px;
  font-family:'Nunito Sans', sans-serif;
  text-transform: uppercase;
  text-align: center;
  padding: 7px 14px 5px 14px;
  outline: none;
}

.btn[disabled] {
  opacity: 0.3;
}

.choice-wrapper .btn {
  margin: 0 20px;
}

.choice p {
  font-size: 25px;
  font-family:'Nunito Sans', sans-serif;
  color:#eeab36;
  text-align: center;
  margin: 20px 0;
}

.game-ended {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family:'Nunito Sans', sans-serif;
  color:#eeab36;
  text-align: center;
  margin-bottom: 40px;
}

.game-ended h2 {
  font-size: 45px;
}

.game-ended h3 {
  font-size: 30px;
  margin-bottom: 1.5em;
}

.game-ended p {
  margin-bottom: 1em;
  color: #fde2bc;
}

.documents {
  font-size: 18px;
  font-family:'Nunito Sans', sans-serif;
  color:#eeab36;
  display: flex;
  padding: 20px;
  overflow: auto;
  flex-wrap: wrap;
  height: 100%;
}

.document {
  flex: 0 0 50%;
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  padding-top: 170px;
  background: url(./doc-closed.svg) no-repeat top center;
  background-size: 170px;
}

.document.read {
  background: url(./doc-open.svg) no-repeat top center;
  background-size: 170px;
}

.document-icon {
  width: 100px;
}

.home-links a {
  margin-right: 1em;
  font-size: 24px;
}

.nav {
  display: flex;
  position: absolute;
  top: 2px;
  right: 80px;
}

::placeholder {
  color: #31241a !important;
  opacity: 0.5 !important;
}

.toast {
  font-family: 'Courier Prime', monospace;
  background: #eeab36;
  color: #31241a;
  padding: 20px 30px;
  font-size: 14px;
  margin-bottom: 20px;
  margin-left: 20px;
  box-shadow: 7px 8px 0px 3px rgba(0,0,0,0.2);
}

.toast-title {
  font-size: 16px;
  font-family:'Nunito Sans', sans-serif;
  text-transform: uppercase;
}
